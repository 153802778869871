import type { FunctionComponent } from 'react'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { ScoreboardLayoutTypes } from '../../index'
import type { ScoreboardScoreProps } from '../default'

import { linkClickEvent } from '@sporza/tracking/events/link-click'
import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Button, { ButtonProps, ButtonSize, ButtonVariant } from '../../../../molecules/button'
import Heading, { HeadingAlignment, HeadingVariant } from '../../../../molecules/heading'
import Score, { ScoreLayoutTypes } from '../../../../molecules/score'
import Teamname, { TeamnameSize } from '../../../../molecules/teamname'
import styles from '../../scoreboard.module.scss'

const ScoreCard: FunctionComponent<ScoreboardScoreProps> = (
  {
    sport,
    status,
    label,
    home,
    away,
    score,
    showLogos,
    showLinks,
    designSystemBaseUrl,
    channels = [],
    heading
  }
) => {
  const filteredChannels = channels.filter((channel: any) =>
    !channel.isHiddenFromMatchDetail
    && channel.iconBefore !== 'channels-sporza-livestream'
  )

  return <div className={clsx(
    styles.scoreboardContentWrapper
  )}>
    {heading && <Heading className={styles.heading} align={HeadingAlignment.Center} variant={HeadingVariant.Tertiary}>{heading}</Heading>}
    <div className={clsx(
      styles.scoreboardContent
    )}>
      <Teamname
        size={TeamnameSize.XLarge}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={false}
        logoPosition='top'
        clamp={2}
      />

      <Score layout={ScoreLayoutTypes.Card}
             score={score}
             sport={sport}
             status={status}
             label={label}
      >
        {
          filteredChannels?.length
            ? <div className={clsx(styles.channels, styles.channelsBig)}>
              <div className={styles.channelsList}>
                {
                  filteredChannels
                    .map((channel: ButtonProps) => {
                      const isPlay6 = channel.iconBefore && channel.iconBefore.indexOf('play-6') > -1
                      const isPlaySports = channel.iconBefore && channel.iconBefore.indexOf('play-sports') > -1
                      const isCanvas = channel.iconBefore && channel.iconBefore.indexOf('canvas') > -1

                      return <Button
                        {...channel}
                        href={undefined}
                        iconBefore={channel.iconBefore}
                        key={channel.iconBefore}
                        variant={ButtonVariant.tertiary}
                        className={clsx(
                          styles.pointerEventsNone,
                          isPlay6 && styles.channelsPlay6,
                          isPlaySports && styles.channelsPlaySports,
                          isCanvas && styles.channelsVrtCanvas,
                        )}
                      />
                    })
                }
              </div>
            </div>
            : null
        }
      </Score>

      <Teamname
        size={TeamnameSize.XLarge}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={false}
        logoPosition='top'
        isAway={true}
        clamp={2}
      />
    </div>
  </div>
}

export default ScoreCard

export type {
  ScoreboardScoreProps,
  ScoreboardLayoutTypes
}
