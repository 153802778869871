import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, RefObject } from 'react'
import type { PlayBannerProps } from './components/play-banner'

import { Theme } from '@sporza/config'
import { useGdpr } from '@sporza/hooks'
import useFeatureToggle from '@sporza/hooks/use-feature-toggle'
import { getCookie } from '@sporza/utils/cookies'
import { addScriptToDOM } from '@sporza/utils/dom'
import { enableConsent, Vendor, VendorId, VendorPurposeId } from '@sporza/utils/gdpr'
import { waitForGlobalAsync } from '@sporza/utils/objects'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { getConfig } from '../../../../apps/frontend-web/app/features/sso/helpers'
import Image, { ImageLayout } from '../../molecules/image'
import { generateDefaultSrc } from '../../molecules/image/helpers'

import { PlayBanner } from './components/play-banner'
import { SimplePlayer } from './components/simple-player'
import {
  AspectRatio,
  AudioType,
  ErrorElement,
  errorHandler,
  getAdConfig,
  getAutoPlayFromUrl,
  getPlayerLibrary,
  LivestreamTitles,
  MediaplayerLayout,
  SkinVariant,
  VideoType
} from './index'
import styles from './mediaplayer.module.scss'

interface MediaplayerComponentProps {
  componentName: string
  componentType: string
  componentProps: MediaplayerProps
}

interface MediaplayerProps extends PropsWithChildren, PlayBannerProps {
  type: VideoType | AudioType
  layout?: MediaplayerLayout
  mediaReferences: string[]
  playerMode?: string
  title?: string
  description?: string
  link?: string
  publicationDate?: string
  program?: string
  media_subtype?: string
  posterImageUrl?: string
  aggregatorUrl?: string
  aspectRatio?: AspectRatio
  cimId?: string
  identityToken?: string
  matchId?: string
  debug?: boolean
  showAds?: boolean
  showTitle?: boolean
  showPosterImage?: boolean
  nextUpAutoPlay?: 'on' | 'off_stop_at_end' | 'off_stop_at_begin'
  autoplay?: boolean
  forceNoConsent?: boolean
  useOutset?: boolean
  isAssetStatusNotAvailableAnymore?: boolean
  darkMode?: boolean
  innerRef?: RefObject<any>
  className?: ClassValue
  addWrapper?: boolean
}

const Mediaplayer: FunctionComponent<MediaplayerProps> = (
  {
    type,
    layout,
    mediaReferences = [],
    playerMode,
    playlistIndexOrRef,
    title,
    description,
    link,
    publicationDate,
    program,
    media_subtype,
    posterImageUrl,
    aggregatorUrl = 'https://media-services-public-stag.vrt.be/vualto-video-aggregator-web/rest/external/v2',
    aspectRatio = '16:9',
    cimId = 'dummy-cim-id',
    identityToken,
    matchId,
    debug = false,
    showAds = false,
    showTitle = true,
    showPosterImage = true,
    nextUpAutoPlay,
    autoplay = false,
    forceNoConsent = false,
    useOutset = false,
    isAssetStatusNotAvailableAnymore = false,
    darkMode = false,
    innerRef,
    className,
    designSystemBaseUrl,
    addWrapper = true,

    // PlayBanner
    themeConfig,
    bannerTargetUrl
  }
) => {
  const playerRef = innerRef ? innerRef : useRef(null)

  const [debugPlayer, setDebugPlayer] = useFeatureToggle('vrtplayer.debug')
  const [featureNewAdConfig] = useFeatureToggle('vrtplayer.newAdConfig')
  const { gdprVendorAllowed } = useGdpr(Vendor.Youtube)
  const [bootstrapped, setBootstrapped] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(false)
  const [config, setConfig] = useState<any>()
  const [showImage, setShowImage] = useState<any>(showPosterImage)
  const [error, setError] = useState<{ title: string, message: any } | null>()

  const isAudio = () => playerMode === 'audio' || playerMode === 'headless'

  const isVideo = () => !isAudio()

  const isYoutube = () => type === VideoType.Youtube

  const getTitle = () => {
    switch (true) {
      case playerMode === 'headless':
        return `${LivestreamTitles[mediaReferences[0]]} - livestream`
      case media_subtype !== undefined:
        return `${title} - ${media_subtype}`
      default:
        return title
    }
  }

  useEffect(() => {
    if (bootstrapped) {
      getAdConfig({
        showAds,
        isAudio: isAudio(),
        type,
        aspectRatio,
        mediaReferences
      }).then(adConfig => {
        const configData = {
          playerConfig: {
            mediaReferences,
            cimId,
            aggregatorUrl,
            preloadMode: 'none',
            playerMode,
            playlistIndexOrRef,
            autoplay: getAutoPlayFromUrl() || autoplay,
            nextUpAutoPlay,
            aspectRatio,
            enableQoE: true // enable mux
          },
          digitalDatas: Array(mediaReferences.length).fill({
            ...window.digitalData,
            media: {
              contentType: 'sp',
              media_subtype
            },
            page: {
              ...window.digitalData.page,
              program_name: program,
              episode_name: getTitle()
            }
          }),
          mediaMetaDatas: Array(mediaReferences.length)
            .fill(null)
            .map((val: number, index: number) => {
              if (index > 0) {
                return null
              }

              return {
                version: '4.0.0',
                type: type === VideoType.Live
                  ? 'live'
                  : isVideo()
                    ? 'vod'
                    : 'aod',
                title,
                posterImageUrl: posterImageUrl && playerMode === 'audio'
                  ? generateDefaultSrc(posterImageUrl, 'native_mobile_400_400')
                  : aspectRatio === AspectRatio._4_5 || aspectRatio === AspectRatio._9_16
                    ? posterImageUrl ? generateDefaultSrc(posterImageUrl, 'native_mobile_800_1200') : undefined
                    : posterImageUrl ? generateDefaultSrc(posterImageUrl, 'width1280') : undefined,
                aVodUrl: adConfig.gamAdUrl,
                aVodType: adConfig.gamAdUrl
                  ? 'gam'
                  : undefined
              }
            }),
          uiConfig: {
            skin: 'sporza',
            skinVariant: darkMode ? SkinVariant.Dark : SkinVariant.Light,
            disableTitle: !showTitle,
            disableUpNextUi: true,
            layout
            // enableCustomActionOne: matchId !== undefined // TODO: we will add back theater mode in SP-7724
          },
          consents: {
            userAllowedYouTube: true // todo implementation with new cookie consent
          }
        }

        if (isAudio()) {
          configData.mediaMetaDatas[0] = {
            ...configData.mediaMetaDatas[0],
            version: '3.0.0',
            type: 'aod',
            description,
            program,
            contentLink: link,
            broadcastDate: publicationDate,
            series: {
              name: program
            }
          }
        }

        setConfig(configData)
      })
    }
  }, [bootstrapped, JSON.stringify(mediaReferences)])

  useEffect(() => {
    waitForGlobalAsync('VRT.SSOPlayerEventHandler').then(() => {
      identityToken = identityToken || getCookie(getConfig().videoTokenCookie)

      const player: any = document.createElement('vrt-mediaplayer')

      if (!isAssetStatusNotAvailableAnymore && config && config.playerConfig) {
        if (identityToken) { // like this or we get errors
          config.playerConfig.identityToken = identityToken
        }
        player.playerConfig = config.playerConfig
        player.digitalDatas = config.digitalDatas
        player.mediaMetaDatas = config.mediaMetaDatas
        player.uiConfig = config.uiConfig
        player.adConfig = config.adConfig
        player.consents = config.consents

        // setShowImage(false) // to unload image inside playerRef, else we can get react error when trying to unmount
        playerRef.current.replaceChild(player, playerRef.current.firstChild)

        player.addEventListener('playerReady', async () => {
          if (!isVideo()) {
            const muted = await playerRef.current.children[0]?.api?.muted
            setMuted(muted)
          }
        })
        player.addEventListener('error', errorHandler(setError))
        player.addEventListener('playnotallowed', (event: any) => window.VRT.SSOPlayerEventHandler(playerRef.current)(event.detail))
        player.addEventListener('canplay', (event: any) => window.VRT.SSOPlayerEventHandler(playerRef.current)(event.detail))

        if (isYoutube() && (forceNoConsent || !gdprVendorAllowed)) {
          setError({
            title: '',
            message: <>
              Hier staat ingevoegde content uit een social media netwerk dat cookies wil schrijven of uitlezen
              (Youtube). U heeft hiervoor geen toestemming gegeven. <a
              className="openSettings"
              href="#"
              onClick={() =>
                enableConsent({
                  vendorId: VendorId.Youtube,
                  vendorPurposeId: VendorPurposeId.Youtube,
                  callback: () => {
                    setError(null)
                  }
                })
              }
            >Klik hier om dit alsnog toe te laten</a>
            </>
          })
        } else {
          setError(null)
        }

        if (debug || debugPlayer?.enabled) console.info('playerConfig:', config)
      }
    })
  }, [config, gdprVendorAllowed])

  useEffect(() => {
    setBootstrapped(!!window.VRTMediaPlayer)

    window.digitalData = window.digitalData || {}
    window.digitalData.page = window.digitalData.page || {}
  }, [])

  useEffect(() => {
    const id = 'newvrtplayer'
    if (!document.getElementById(id)) {
      addScriptToDOM({
        src: getPlayerLibrary(debugPlayer.enabled && debugPlayer.env),
        id
      })

      window.addEventListener('vrtPlayerBootstrapped', () => {
        setBootstrapped(true)
      })
    } else {
      setBootstrapped(true)
    }
  }, [debugPlayer])

  return <>
    <div
      className={clsx(
      addWrapper && 'mediaplayer-wrapper',
      styles.wrapper,
      useOutset && styles.outset,
      isVideo() ? styles.video : undefined,
      isVideo() && aspectRatio === AspectRatio._4_5 ? styles.video45 : undefined,
      isVideo() && aspectRatio === AspectRatio._9_16 ? styles.video916 : undefined,
      className
    )}
      key="mediaplayer"
    >
      <div ref={playerRef}>
        {
          isVideo() && showImage
            ? <Image
              src={posterImageUrl}
              layout={
                aspectRatio === AspectRatio._9_16
                  ? ImageLayout.Portrait9x16
                  : aspectRatio === AspectRatio._4_5
                    ? ImageLayout.Portrait
                    : ImageLayout.Widescreen
              }
              darkMode={true}
            />
            : <div></div>
        }
      </div>
      {
        isAssetStatusNotAvailableAnymore
        && <ErrorElement
          title="Video niet meer beschikbaar"
          message="Onze excuses, maar deze video is niet langer te bekijken via sporza"
        />
      }
      {
        playerMode === 'headless'
        && playerRef.current
        && <SimplePlayer
          playerRef={playerRef}
          title={title}
          playing={playing}
          setPlaying={setPlaying}
          muted={muted}
          setMuted={setMuted}
        />
      }
      {
        error
        && <ErrorElement {...error}>
          {isVideo() && <Image className={styles.errorThumb} src={posterImageUrl} darkMode={true}/>}
        </ErrorElement>
      }
    </div>
    {
      themeConfig?.theme === Theme.GenericPlay
        ? <PlayBanner
          key="play-banner"
          themeConfig={themeConfig}
          bannerTargetUrl={bannerTargetUrl}
          designSystemBaseUrl={designSystemBaseUrl}
        />
        : null
    }
  </>
}

export default Mediaplayer

export type {
  MediaplayerProps,
  MediaplayerComponentProps
}
